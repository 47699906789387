import { useState, useEffect } from "react";

import { collection, doc, getDocs } from "firebase/firestore";
import { firestore } from "mythulu-core/integrations/firebase";
import Content from "mythulu-core/layouts/Content";


export default function LayoutsManager() {
  const [layouts, setLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const layoutsCollection = collection(firestore, "layouts_mythulu");
      const layoutsSnapshot = await getDocs(layoutsCollection);
      const fetchedLayouts = layoutsSnapshot.docs.reduce((acc, docSnapshot) => {
        acc.push({ id: docSnapshot.id, ...docSnapshot.data()});
        return acc;
      }, []);
      setLayouts(fetchedLayouts.sort((a, b) => a.title?.localeCompare(b.title || "")));
    }
    fetchData();
  }, []);

  function handleSelectLayout(id) {
    setSelectedLayout(layouts.find((layout) => layout.id === id));
  }

  return (
    <Content>

      <div className="flex text-center">
        <div className="flex flex-col w-1/4 p-4">
          <h2 className="font-brand text-2xl">Layouts</h2>
          {selectedLayout ? (
            <button className="text-sm text-gray-400 underline" onClick={() => {
              setSelectedLayout(null);
            }}>Deselect</button>
          ) : "—"}
        </div>
        <div className="flex flex-col w-3/4 ml-l4 p-4">
          <h2 className="font-brand text-2xl">Layout</h2>
          <span className="text-rose-500">{selectedLayout ? selectedLayout.title || '(Untitled)' : '—'}</span>
        </div>

      </div>

      <div className="flex">
        <div className="flex flex-col w-1/4 bg-slate-500 p-4 rounded">
          {layouts.map(({ id, ...layout }) => (
            <button
              key={id}
              className={`p-2 m-1 rounded bg-slate-900 text-white ${
                selectedLayout && selectedLayout.id === id ? "ring-2 ring-red-400 bg-rose-700" : ""
              }`}
              onClick={() => handleSelectLayout(id)}
            >
              {layout.title || "(Untitled)"}
            </button>
          ))}
        </div>
        <div className="w-3/4 bg-slate-800 ml-4 p-4 rounded">
          {selectedLayout && (
            <SelectedLayoutEditor layout={selectedLayout} />
          )}
        </div>
      </div>
    </Content>
  );
}


function SelectedLayoutEditor({ layout }) {

  return null;
}
