import { useState, useEffect } from "react";

import { collection, doc, getDocs } from "firebase/firestore";
import { firestore } from "mythulu-core/integrations/firebase";
import Content from "mythulu-core/layouts/Content";
import useFileUpload from "mythulu-core/hooks/useFileUpload";


export default function ExploreManager() {
  const [slides, setSlides] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const slidesCollection = collection(firestore, "explore_slides");
      const slidesSnapshot = await getDocs(slidesCollection);
      const fetchedSlides = slidesSnapshot.docs.reduce((acc, docSnapshot) => {
        acc.push({ id: docSnapshot.id, ...docSnapshot.data()});
        return acc;
      }, []);
      setSlides(fetchedSlides.sort((a, b) => a.title?.localeCompare(b.title || "")));
    }
    fetchData();
  }, []);

  function handleSelectSlide(id) {
    setSelectedSlide(slides.find((slide) => slide.id === id));
  }

  return (
    <Content>

      <div className="flex text-center">
        <div className="flex flex-col w-1/4 p-4">
          <h2 className="font-brand text-2xl">Slides</h2>
          {selectedSlide ? (
            <button className="text-sm text-gray-400 underline" onClick={() => {
              setSelectedSlide(null);
            }}>Deselect</button>
          ) : "—"}
        </div>
        <div className="flex flex-col w-3/4 ml-l4 p-4">
          <h2 className="font-brand text-2xl">Slide</h2>
          <span className="text-rose-500">{selectedSlide ? selectedSlide.title || '(Untitled)' : '—'}</span>
        </div>

      </div>

      <div className="flex">
        <div className="flex flex-col w-1/4 bg-slate-500 p-4 rounded">
          {slides.map(({ id, ...slide }) => (
            <button
              key={id}
              className={`p-2 m-1 rounded bg-slate-900 text-white ${
                selectedSlide && selectedSlide.id === id ? "ring-2 ring-red-400 bg-rose-700" : ""
              }`}
              onClick={() => handleSelectSlide(id)}
            >
              {slide.title || "(Untitled)"}
            </button>
          ))}
        </div>
        <div className="w-3/4 bg-slate-800 ml-4 p-4 rounded">
          {selectedSlide && (
            <SelectedSlideEditor slide={selectedSlide} />
          )}
        </div>
      </div>
    </Content>
  );
}


function SelectedSlideEditor({ slide }) {
  const { file, DropZone } = useFileUpload();
  const [imageDimensions, setImageDimensions] = useState({});

  useEffect(() => {
    if (slide.avatar && !file) {
      const img = new Image();
      img.src = slide.avatar;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [slide.avatar, file]);

  return (
    <form>
      <div className="text-center mt-1">
        <button type="submit" className="mb-2 py-2 px-15 bg-rose-600 text-white rounded">
          Save Changes
        </button>
      </div>

      <label className="block mt-5 mb-2 font-semibold">Title</label>
      <input
        type="text"
        value={slide.title}
        onChange={(e) => console.log("Update title", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 bg-slate-900 border border-slate-900 rounded"
      />

      <label className="block mb-2 font-semibold">Subtitle</label>
      <textarea
        value={slide.subtitle}
        onChange={(e) => console.log("Update description", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 h-16 bg-slate-900 border border-slate-900 rounded"
      />

      <label className="block mb-2 font-semibold">Link Target</label>
      <input
        type="url"
        value={slide.link}
        onChange={(e) => console.log("Update title", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 bg-slate-900 border border-slate-900 rounded"
      />

      <div className="mb-4">
        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            onLoad={(e) => setImageDimensions({ width: e.target.width, height: e.target.height })}
          />
        ) : (
          <img
            src={slide.image}
            alt={slide.title}
          />
        )}
        <p className="mt-2 italic">
          Dimensions: {imageDimensions.width}x{imageDimensions.height}
        </p>
        <DropZone className="bg-slate-900 mt-2">
          {file ? (
            <div className="text-left">
              <p className="p-2 font-semibold">
                {file.name}
              </p>
              <p className="p-2">
                {(file.size / 1024 / 1024).toLocaleString().slice(0, 4)} MB •{" "}
                {file.type}
              </p>
            </div>
          ) : (
            <p className="text-left font-semibold">
              Drag and drop a file here, or click to select a file
            </p>
          )}
        </DropZone>
      </div>

    </form>
  );
}
