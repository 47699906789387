import Content from 'mythulu-core/layouts/Content';


export default function Home() {
  return (
    <Content>
      <h1 className="text-5xl text-center font-brand text-shadow-xl shadow-black m-10">Mythulu Creation Cards</h1>

      <div className="text-center">
        <div className="inline-flex p-15 m-10 border-1 border-slate-700 bg-slate-700 rounded-xl shadow-inner shadow-xl shadow-slate-900">
          <a
            href="https://apps.apple.com/us/app/mythulu-creation-cards/id1442867455?itscg=30200&amp;itsct=apps_box_appicon"
            className="inline-block shadow-xl shadow-slate-900 border-1 border-slate-700"
            style={{ width: "170px", height: "170px", borderRadius: "22%", overflow: "hidden", verticalAlign: "middle" }}
          ><img
            src="https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/36/71/14/3671146e-9ef4-ab90-c45d-f1b6c8cd2e14/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/540x540bb.jpg"
            alt="Mythulu Creation Cards"
            className="inline-block"
            style={{ width: "170px", height: "170px", borderRadius: "22%", overflow: "hidden", verticalAlign: "middle" }}
          /></a>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-center">

        <div className="flex items-center">
          <a
            href="https://apps.apple.com/us/app/mythulu-creation-cards/id1442867455?itsct=apps_box_badge&amp;itscg=30200"
            className="inline-block"
            style={{ overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px" }}
          ><img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1551484800"
            alt="Download on the App Store"
            style={{ borderRadius: "13px", width: "250px", height: "83px" }}
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=com.mythulu.app&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            className="inline-block"
          ><img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ width: "300px" }}
          /></a>
        </div>
      </div>
    </Content>
  );
}
