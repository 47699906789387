import { createContext, useReducer } from 'react';
import { getDocs, collection } from 'firebase/firestore';

import { firestore } from 'mythulu-core/integrations/firebase';


const Context = createContext();
export default Context;

export function AuthProvider(props) {
  const [ state, dispatch ] = useReducer(reducer, defaultState);
  const context = makeContext(state, dispatch);

  return (
    <Context.Provider value={context} {...props} />
  )
}

const defaultState = {
  user: null,
  claims: {
    user_id: null,
    isAdmin: null,
    email: null,
    name: null,
    picture: null,
  },
  decks: {},
  cards: {},
  layouts: {},
};

function makeContext(state, dispatch) {
  const context = {
    isAdmin: () => state.claims.isAdmin,
    getUID: () => state.claims.user_id,
    getEmail: () => state.claims.email,
    getUser: () => state.user,
    getDecks: () => state.decks,
    getCards: () => state.cards,
    getLayouts: () => state.layouts,

    preload: async () => {
      getDocs(collection(firestore, 'decks')).then((snapshot) => {
        const decks = {};
        const cards = {};
        snapshot.forEach((doc) => {
          const deck = doc.data();
          decks[deck.deck_id] = deck;
          deck.cards.forEach((card) => {
            cards[card.card_id] = card;
          });
        });
        dispatch({ type: 'SET_DECKS', decks });
        dispatch({ type: 'SET_CARDS', cards });
      });
      getDocs(collection(firestore, 'layouts_mythulu')).then((snapshot) => {
        const layouts = {};
        snapshot.forEach((doc) => {
          const layout = doc.data();
          layouts[doc.id] = layout;
        });
        dispatch({ type: 'SET_LAYOUTS', layouts });
      });
    },

    setAuthUser: (user) => {
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          dispatch({ type: 'SET_USER', user, claims });
        });
      } else {
        dispatch({ type: 'SET_USER', user: null, claims: { isAdmin: null } });
      }
    },
  };
  return context;
}

function reducer(previousState, { type, ...payload }) {
  console.log({ type, payload });
  switch (type) {
    case 'SET_USER':
      return { ...previousState, user: payload.user, claims: payload.claims };
    case 'SET_DECKS':
      return { ...previousState, decks: payload.decks };
    case 'SET_CARDS':
      return { ...previousState, cards: payload.cards };
    case 'SET_LAYOUTS':
      return { ...previousState, layouts: payload.layouts };
    default:
      throw new Error(`Unknown action ${type}`);
  }
}
