import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import AuthContext from "mythulu-core/contexts/auth";
import Content from 'mythulu-core/layouts/Content';
import NotFound from 'mythulu-core/pages/NotFound';


export default function Admin() {
  const { isAdmin } = useContext(AuthContext);

  return (
    <Content>
      {isAdmin() ? <Outlet /> : <NotFound />}
    </Content>
  );
}
