import * as config from 'mythulu-core/config';

export const links = [
  { name: 'Cards', to: '/', active: true },
  { name: 'E-Magazine', to: 'https://magazine.mythulu.com/' },
  { name: 'Store', to: 'https://mythulu.com/shop/' },
  { name: 'Contact Us', to: 'https://mythulu.com/contact-us/' },
];


export const firebaseConfig = {
  apiKey: "AIzaSyBJV7r3xMxkq-DIkTpQGY7X4g_Th1sT58Q",
  authDomain: "mythulu-app.firebaseapp.com",
  databaseURL: "https://mythulu-app-default-rtdb.firebaseio.com",
  projectId: "mythulu-app",
  storageBucket: "mythulu-app.appspot.com",
  messagingSenderId: "37323338207",
  appId: "1:37323338207:web:c1ad0aec02c40e9175546a",
  measurementId: "G-JQTJYZQD2G"
};


export const {
    MAINTENANCE_MODE,
    // MAINTENANCE_MESSAGE,
    FIREBASE_EMULATOR_DATABASE_HOST,
    FIREBASE_EMULATOR_DATABASE_PORT,
    FIREBASE_EMULATOR_FIRESTORE_URL,
    FIREBASE_EMULATOR_AUTH_URL,
} = config;
