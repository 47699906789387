import { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';


const useFileUpload = () => {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const DropZone = useMemo(() => {
    return ({ className, children }) => (
      <>
        <div {...getRootProps()} className={`p-4 rounded flex flex-col items-center justify-center space-y-2 ${className}`}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="font-semibold">Drop the file here...</p>
          ) : children}
        </div>
      </>
    );
  }, [isDragActive]);

  return { file, DropZone };
}

export default useFileUpload;
