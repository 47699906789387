import { createContext, useReducer } from 'react';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

import { firestore } from 'mythulu-core/integrations/firebase';


const Context = createContext();
export default Context;

export function ProfileProvider({ uid, ...props }) {
  const [ state, dispatch ] = useReducer(reducer, defaultState);
  const context = makeContext(state, dispatch, { uid });

  return (
    <Context.Provider value={context} {...props} />
  )
}

export const defaultState = {
  uid: null,

  profile: {
    _firebase: {
      uid: null,
      displayName: "(Nothing)",
      emailVerified: null,
    },

    checks: {
      kickstarter_2022: null,
    },
    enabled: {
      decks: {},
      layouts: {
        mythulu: {},
      }
    },
    settings: {},

    currency: {
      coins: null,
      log: [],
      ks_2022_coins: false,
    },

    // Additional flags
    migrated: false,
  },
};

export function makeContext(state, dispatch, { uid }) {

  function _doc() {
    return doc(firestore, 'users', uid);
  }

  async function _load() {
    return (await getDoc(_doc(uid))).data();
  }

  async function _update(data) {
    console.log(uid, data);
    await setDoc(_doc(), data, { merge: true });
  }

  const context = {
    uid,
    profile: () => state.profile,
    load: async () => dispatch({
      type: 'SET_PROFILE',
      uid,
      profile: await _load(),
    }),

    patchProfile: (data) => _update(data),
    addSupportCoins: (id, coins) => {
      const timestamp = new Date().toISOString();
      const newBalance = (parseInt(state.profile.currency.coins) || 0) + (parseInt(coins) || 0);
      const log = {
        id,
        type: 'coins',
        cost: -coins,
        newBalance,
        oldBalance: state.profile.currency?.coins || 0,
        timestamp,
        context: {
          source: "promo",
          timestamp,
          code: id,
          coins,
        },
      };
      context.patchProfile({
        currency: {
          coins: newBalance,
          log: [log, ...(state.profile.currency.log || [])],
        },
      });
    },
  };
  return context;
}

export function reducer(previousState, { type, ...payload }) {
  console.log({ type, payload });
  switch (type) {
    case 'SET_PROFILE':
      return { ...previousState, uid: payload.uid, profile: payload.profile };
    default:
      throw new Error(`Unknown action ${type}`);
  }
}
