import { useContext, useState, useEffect } from "react";

import { firestore, storage } from "mythulu-core/integrations/firebase";
import AuthContext from "mythulu-core/contexts/auth";
import Content from "mythulu-core/layouts/Content";
import useFileUpload from "mythulu-core/hooks/useFileUpload";


export default function CardsManager() {
  const { getDecks, getCards } = useContext(AuthContext);
  const [decks, setDecks] = useState([]);
  const [cards, setCards] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [filteredCards, setFilteredCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    setDecks(Object.entries(getDecks()).map(
      ([id, deck]) => ({ id, ...deck })
    ).sort((a, b) => a.title.localeCompare(b.title)));
    setCards(Object.entries(getCards()).map(
      ([id, card]) => ({ id, ...card })
    ).sort((a, b) => a.title.localeCompare(b.title)));
  }, [getDecks, getCards]);

  useEffect(() => {
    setFilteredCards(cards.filter((card) => !selectedDeck || card.deck_id === selectedDeck.id));
  }, [selectedDeck, cards]);

  return (
    <Content>
      <div className="flex text-center">
        <div className="flex flex-col w-1/3 p-4">
          <h2 className="font-brand text-2xl">Deck</h2>
          <span className="text-rose-500">{selectedDeck?.title || '—'}</span>
        </div>
        <div className="flex flex-col w-1/6 ml-4 p-4">
          <h2 className="font-brand text-2xl">Decks</h2>
          {selectedDeck ? (
            <button className="text-sm text-gray-400 underline" onClick={() => {
              setSelectedCard(null);
              setSelectedDeck(null);
            }}>Deselect</button>
            ) : "—"}
        </div>
        <div className="flex flex-col w-1/6 p-4 mx-4">
          <h2 className="font-brand text-2xl">Cards</h2>
          {selectedCard ? (
            <button className="text-sm text-gray-400 underline" onClick={() => setSelectedCard(null)}
            >Deselect</button>
          ) : "—"}
        </div>
        <div className="flex flex-col w-1/3 p-4">
          <h2 className="font-brand text-2xl">Card</h2>
          <span className="text-rose-500">{selectedCard?.title || '—'}</span>
        </div>

      </div>
      <div className="flex">
        {/* Deck */}
        <div className="w-1/3 bg-slate-800 p-4 rounded">
          {selectedDeck && (
            <DeckContentEditor deck={selectedDeck} />
          )}
        </div>

        {/* Decks column */}
        <div className="flex flex-col w-1/6 bg-slate-500 p-4 ml-4 rounded">
          {decks.map((deck) => (
            <button
              key={deck.id}
              className={`p-2 m-1 rounded bg-slate-900 text-white ${
                selectedDeck && selectedDeck.id === deck.id ? "ring-2 ring-red-400 bg-rose-700" : ""
              }`}
              onClick={() => setSelectedDeck(deck)}
            >
              {deck.title}
            </button>
          ))}
        </div>

        {/* Cards column */}
        <div className="flex flex-col w-1/6 overflow-y-auto bg-slate-600 p-4 mx-4 rounded">
          {filteredCards.map((card) => (
            <button
              key={card.id}
              className={`p-2 m-1 rounded bg-slate-900 text-white ${
                selectedCard && selectedCard.id === card.id ? "ring-2 ring-red-400 bg-rose-700" : ""
              }`}
              onClick={() => setSelectedCard(card)}
            >
              {card.title}
            </button>
          ))}
        </div>

        {/* Card */}
        <div className="w-1/3 bg-slate-800 p-4 rounded">
          {selectedCard && (
            <CardContentEditor card={selectedCard} getDecks={getDecks} />
          )}
        </div>
      </div>
    </Content>
  );
}


function DeckContentEditor({ deck }) {
  const { file/* , DropZone */ } = useFileUpload();
  const [imageDimensions, setImageDimensions] = useState({});

  useEffect(() => {
    if (deck.avatar && !file) {
      const img = new Image();
      img.src = deck.avatar;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [deck.avatar, file]);

  return (
    <form>
      <div className="text-center mt-1">
        <button type="submit" className="mb-2 py-2 px-15 bg-rose-600 text-white rounded">
          Save Changes
        </button>
      </div>

      <div className="text-center text-sm text-gray-400">
        <p>Created at: {new Date(deck.created_at.seconds * 1000).toLocaleString()}</p>
        <p>Updated at: {new Date(deck.updated_at.seconds * 1000).toLocaleString()}</p>
      </div>

      <label className="block mt-5 mb-2 font-semibold">Title</label>
      <input
        type="text"
        value={deck.title}
        className="w-full p-2 mb-4 bg-slate-900 border border-slate-900 rounded"
      />

      <label className="block mt-5 mb-2 font-semibold">Settings</label>
      <div className="flex flex-col mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            defaultChecked={deck.is_expansion}
            className="mr-2 bg-slate-900 border-slate-500 rounded-md"
          />
          Is Expansion
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            defaultChecked={deck.is_paid}
            className="mr-2 bg-slate-900 border-slate-500 rounded-md"
          />
          Is Paid
        </label>
      </div>

      <div className="mb-4">
        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            onLoad={(e) => setImageDimensions({ width: e.target.width, height: e.target.height })}
          />
        ) : (
          <img
            src={deck.avatar}
            alt={deck.title}
          />
        )}
        <p className="mt-2 italic">
          Dimensions: {imageDimensions.width}x{imageDimensions.height}
        </p>
        {/* <DropZone className="bg-slate-900 mt-2">
          {file ? (
            <div className="text-left">
              <p className="p-2 font-semibold">
                {file.name}
              </p>
              <p className="p-2">
                {(file.size / 1024 / 1024).toLocaleString().slice(0, 4)} MB •{" "}
                {file.type}
              </p>
            </div>
          ) : (
            <p className="text-left font-semibold">
              Drag and drop a file here, or click to select a file
            </p>
          )}
        </DropZone> */}
      </div>

      <label className="block mb-2 font-semibold">Description</label>
      <textarea
        value={deck.description}
        onChange={(e) => console.log("Update description", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 h-32 bg-slate-900 border border-slate-900 rounded"
      />
    </form>
  );
}


function CardContentEditor({ card, getDecks }) {
  const { file/* , DropZone */ } = useFileUpload();
  const [imageDimensions, setImageDimensions] = useState({});

  useEffect(() => {
    if (card.avatar && !file) {
      const img = new Image();
      img.src = card.avatar;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [card.avatar, file]);

  return (
    <form>
      <div className="text-center mt-1">
        <button type="submit" className="mb-2 py-2 px-15 bg-rose-600 text-white rounded">
          Save Changes
        </button>
      </div>

      <div className="text-center text-sm text-gray-400">
        <p>Created at: {new Date(card.created_at.seconds * 1000).toLocaleString()}</p>
        <p>Updated at: {new Date(card.updated_at.seconds * 1000).toLocaleString()}</p>
      </div>

      <label className="block mt-5 mb-2 font-semibold">Title</label>
      <input
        type="text"
        value={card.title}
        onChange={(e) => console.log("Update title", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 bg-slate-900 border border-slate-900 rounded"
      />

      <p className="mb-4">
        Deck:
        <div className="ml-2 inline-block rounded-full bg-yellow-600 border border-yellow-900 px-2 text-sm">
          {getDecks()[card.deck_id].title}
        </div>
      </p>

      <div className="mb-4">
        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            onLoad={(e) => setImageDimensions({ width: e.target.width, height: e.target.height })}
          />
        ) : (
          <img
            src={card.avatar}
            alt={card.title}
          />
        )}
        <p className="mt-2 italic">
          Dimensions: {imageDimensions.width}x{imageDimensions.height}
        </p>
        {/* <DropZone className="bg-slate-900 mt-2">
          {file ? (
            <div className="text-left">
              <p className="p-2 font-semibold">
                {file.name}
              </p>
              <p className="p-2">
                {(file.size / 1024 / 1024).toLocaleString().slice(0, 4)} MB •{" "}
                {file.type}
              </p>
            </div>
          ) : (
            <p className="text-left font-semibold">
              Drag and drop a file here, or click to select a file
            </p>
          )}
        </DropZone> */}
      </div>

      <label className="block mb-2 font-semibold">Description</label>
      <textarea
        value={card.description}
        onChange={(e) => console.log("Update description", e.target.value)} // Replace with your update function
        className="w-full p-2 mb-4 h-32 bg-slate-900 border border-slate-900 rounded"
      />
    </form>
  );
}
