import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'mythulu-core/integrations/firebase';

// import AdminBar from 'mythulu-core/components/bars/AdminBar';
import Content from 'mythulu-core/layouts/Content';


function SearchInput({ label, field, onSubmit, range=false }) {
  const [begin, setBegin] = useState('');

  const handleSearch = useCallback((event) => {
    event.preventDefault();
    onSubmit(field, begin, `${begin}\uf8ff`);
  }, [field, begin, onSubmit]);

  return (
    <form onSubmit={handleSearch} className="mb-1">
      <label>
        <strong className="text-gray-300 inline-block w-36">
          {label}
        </strong>
        <input type="text" value={begin} onChange={event => {
          setBegin(event.target.value);
        }}
          className="bg-slate-700 text-slate-100 py-2 px-4 rounded-sm"
        />
        <input type="submit" value="Search"
          className="ml-2 bg-slate-800 hover:bg-slate-700 text-slate-100 font-bold py-2 px-4 rounded"
        />
      </label>
    </form>
  );
}

export default function UserSearch() {
  const [users, setUsers] = useState([]);

  const handleSearch = useCallback(async (field, begin, end) => {
    const justBegin = begin !== '' && end === '';
    const justEnd = begin === '' && end !== '';
    const constraints = [
      where(field, (justBegin ? '==' : '>='), begin),
      justBegin ? null : where(field, (justEnd ? '==' : '<='), end),
    ].filter(Boolean);
    const q = query(collection(firestore, 'users'), ...constraints);

    const snapshot = await getDocs(q);
    const items = [];
    snapshot.docs.forEach(doc => {
      items.push({ id: doc.id, ...doc.data() })
    });
    setUsers(items);
  }, []);

  return (
    <>
      {/* <AdminBar buttons={[
        {"label": "Search", "to": "/admin/search"},
      ]} /> */}

      <Content>
        <h3 className="text-xl">Search</h3>
        <div className="mb-5">
          <SearchInput label="UID" field="_firebase.uid" onSubmit={handleSearch} />
          <SearchInput label="Display Name" field="_firebase.displayName" onSubmit={handleSearch} />
          <SearchInput label="Email" field="_firebase.email" onSubmit={handleSearch} />
        </div>
        <h3 className="text-xl">{users.length} Results</h3>
        {!users.length ? "—" : (
          <table className="table-auto w-full text-left text-dark dark:text-slate-100">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Coins</th>
                <th>Log Entries</th>
                <th>Decks</th>
                <th>Layouts</th>
                <th>Hands</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.id} className={`hover:bg-blue-700`}>
                  <td className="pl-2 m-0">
                    <div className="p-2 bg-slate-800 hover:bg-slate-900 rounded">
                      <Link to={`/admin/profile/${user.id}`} className="text-emerald-300">
                        <img src={user._firebase?.photoURL} alt="modern profile" width="35" className="inline-block rounded-full" />
                        <strong>&nbsp;&nbsp;{user._firebase?.displayName || user.id}</strong>
                      </Link>
                      <div className="text-right">
                        <small className="text-slate-400">
                          via {user._firebase?.providerData?.map(provider => provider.providerId).join(', ')}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td className="pl-2">
                    <div><a className="underline" href={
                      user._firebase?.providerData?.[0]?.email
                        ? `mailto:${user._firebase?.providerData?.[0]?.email}`
                        : "#"
                    }>{user._firebase?.providerData?.[0]?.email}</a></div>
                    <span
                      className="text-xs bg-slate-900 px-2 py-1"
                    >id: {user.id}</span>
                  </td>
                  <td>{user.currency?.coins || <Null />}</td>
                  <td>{user.currency?.log?.length || <Null />}</td>
                  <td>{user.currency?.log?.filter(entry => entry.type === 'decks').length || <Null />}</td>
                  <td>{user.currency?.log?.filter(entry => entry.type === 'layouts').length || <Null />}</td>
                  <td>{user.hands?.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Content>
    </>
  );
}


function Null() {
  return <span className="text-slate-500">—</span>;
}
