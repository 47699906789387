import { HashRouter, Routes, Route } from 'react-router-dom';

import GlobalFrame from 'mythulu-core/layouts/global/GlobalFrame';
import Login from 'mythulu-core/pages/Login';
import NotFound from 'mythulu-core/pages/NotFound';

import Home from './pages/Home';
import Admin from './pages/Admin';
import UserSearch from './pages/UserSearch';
import UserDetail from './pages/UserDetail';
import CardsManager from './pages/CardsManager';
import LayoutsManager from './pages/LayoutsManager';
// import PromosManager from './pages/PromosManager';
import ExploreManager from './pages/ExploreManager';


export default function App() {
  return (
    <HashRouter>

      <GlobalFrame
        nav={[
          { name: 'Apps', to: '/' },
          // { name: 'Pack A', to: '#/starter-pack-a' },
          // { name: 'Pack B', to: '#/starter-pack-b' },
          // { name: 'Tutorials', to: '#/tutorials' },
        ]}
        admin={[
          { name: 'Cards', to: '#/admin/cards' },
          { name: 'Layouts', to: '#/admin/layouts' },
          // { name: 'Promos', to: '#/admin/promos' },
          { name: 'Explore', to: '#/admin/explore' },
          { name: 'Profile Search', to: '#/admin/search' },
          { name: 'Profile', to: '#/admin/profile/:uid' },
        ]}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/tutorials" element={<Tutorials />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="cards" element={<CardsManager />} />
            <Route path="layouts" element={<LayoutsManager />} />
            {/* <Route path="promos" element={<PromosManager />} /> */}
            <Route path="explore" element={<ExploreManager />} />
            <Route path="search" element={<UserSearch />} />
            <Route path="profile/:uid" element={<UserDetail />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </GlobalFrame>

    </HashRouter>
  );
}
